export default [
  {
    path: '/offres-injectees',
    name: 'specialOfferList',
    component: () => import('@/views/SpecialOffer/InjectedOffer/List.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/offres-injectees/creation',
    name: 'specialOfferCreate',
    component: () => import('@/views/SpecialOffer/InjectedOffer/Create.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/offres-injectees/:specialOfferId',
    name: 'specialOfferDetails',
    component: () => import('@/views/SpecialOffer/InjectedOffer/Details.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/offres-personnalisees',
    name: 'specialOfferPersonalizedList',
    component: () => import('@/views/SpecialOffer/PersonalizedOffer/List.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/offres-personnalisees/creation',
    name: 'specialOfferPersonalizedCreate',
    component: () => import('@/views/SpecialOffer/PersonalizedOffer/Create.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/offres-personnalisees/:specialOfferId',
    name: 'specialOfferPersonalizedDetails',
    component: () => import('@/views/SpecialOffer/PersonalizedOffer/Details.vue'),
    meta: {
      layout: 'content'
    }
  }
]
