export default [
  {
    path: '/seo/url-bloquees',
    name: 'blockedUrlList',
    component: () => import('@/views/Seo/BlockedUrl/List.vue'),
    meta: {
      layout: 'content'
    }
  }
]
