<template>
  <v-card>
    <Loader
      v-if="loading"
      :key="generateUniqueId()"
      :loader-state="loading"
    ></Loader>

    <v-card-title>Nuage de liens</v-card-title>

    <LocalSwitcher
      @updateCurrentLocale="updateCurrentLocale($event)"
    ></LocalSwitcher>

    <v-card-text>
      <v-form
        v-model="valid"
        class="multi-col-validation mt-5"
        @submit.prevent="checkForm"
      >
        <p v-if="errors && errors.length">
          <v-alert
            v-for="(error, index) in errors"
            :key="index"
            text
            dense
            type="error"
          >
            {{ error.title }}
          </v-alert>
        </p>

        <Snackbar
          v-if="successMessage"
          :key="generateUniqueId()"
          :snackbar-state="snackbar"
          :success-message="successMessage"
        ></Snackbar>

        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <div class="content-ckeditor-region">
              <h4>Description</h4>
              <br>
              <ckeditor
                v-model="description[currentLocale]"
                :editor="editor"
                :config="editorConfig"
                height="10"
                row="50"
              />
              <country-flag
                class="inner-input-flag content-flag-placeholder"
                :country="currentLocale === 'en' ? 'gb' : currentLocale"
                size="normal"
                shadow
              />
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-btn
              class="mt-5 mr-2"
              color="primary"
              :disabled="!valid"
              type="submit"
            >
              Valider
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Vue from 'vue'
import config from '../../../config'
import Snackbar from '@/components/Common/Snackbar'
import Loader from '@/components/Common/Loader'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import FullEditor from 'ckeditor5-custom-build/build/ckeditor'
import {
  mdiCameraOutline,
  mdiCompassRose,
  mdiEyeOffOutline,
  mdiEyeOutline,
  mdiMinusBox,
  mdiPencil, mdiSkiWater, mdiThumbUp
} from '@mdi/js'
import LocalSwitcher from '@/components/Common/LocalSwitcher.vue'

export default {
  components: {
    LocalSwitcher,
    Snackbar,
    Loader,
    ckeditor: CKEditor.component
  },
  data () {
    return {
      valid: false,
      errors: [],
      snackbar: false,
      successMessage: null,
      loading: false,
      currentLocale: 'fr',
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiCameraOutline,
        mdiMinusBox,
        mdiPencil,
        mdiCompassRose,
        mdiSkiWater,
        mdiThumbUp
      },
      editor: FullEditor,
      editorConfig: {
        toolbar: {
          shouldNotGroupWhenFull: true
        },
        heading: {
          options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
            { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
            { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
            { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
            { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' }
          ]
        },
        htmlSupport: {
          allow: [
            {
              name: /.*/,
              attributes: true,
              classes: true,
              styles: true
            }
          ]
        },
        simpleUpload: {
          uploadUrl: config.apiUrl + '/api/medias',
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        }
      },
      id: [],
      description: [],
      linkCloud: null,
      entityData: {
        id: null,
        currentLocale: 'fr',
        defaultLocale: 'fr',
        newTranslations: {}
      }
    }
  },
  computed: {
  },
  created () {
    this.getLinkCLoud()
  },
  methods: {
    checkForm () {
      this.errors = []

      if (this.valid) {
        this.linkCloud.id ? this.putLinkCLoud() : this.postLinkCLoud()
      }
    },
    getLinkCLoud () {
      this.loading = true

      Vue.prototype.$http
        .get(config.apiUrl + '/api/link-cloud', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 200) {
            this.linkCloud = response.data
            this.entityData.id = this.linkCloud.id

            const translations = this.linkCloud.translations

            for (const [currentKey] of Object.entries(translations)) {
              let currentTranslation = translations[currentKey]

              this.id[currentKey] = currentTranslation.id
              this.description[currentKey] = currentTranslation.description
            }
          }

          this.valid = true
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    postLinkCLoud () {
      this.valid = false
      this.successMessage = null
      this.loading = true

      this.handleTranslations([
        'description'
      ])

      Vue.prototype.$http
        .post(config.apiUrl + '/api/link-cloud', this.entityData, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 201) {
            this.selection = response.data
            this.errors = []
            this.snackbar = true
            this.successMessage = 'Nuage de liens mis à jour avec succès.'
            this.valid = true
          }

          this.loading = false
        })
        .catch(e => {
          const response = e.response

          this.successMessage = null

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
          }

          this.valid = true
          this.loading = false
        })
    },
    putLinkCLoud () {
      this.valid = false
      this.successMessage = null
      this.loading = true

      this.handleTranslations([
        'description'
      ])

      Vue.prototype.$http
        .put(config.apiUrl + '/api/link-cloud/' + this.linkCloud.id, this.entityData, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 200) {
            this.errors = []
            this.snackbar = true
            this.successMessage = 'Nuage de liens mis à jour avec succès.'
            this.valid = true

            this.linkCloud = response.data

            const translations = this.linkCloud.translations

            for (const [currentKey] of Object.entries(translations)) {
              let currentTranslation = translations[currentKey]

              this.id[currentKey] = currentTranslation.id
              this.description[currentKey] = currentTranslation.description
            }
          }

          this.loading = false
        })
        .catch(e => {
          const response = e.response

          this.successMessage = null

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
          }

          this.valid = true
          this.loading = false
        })
    },
    handleTranslations (attributes) {
      for (let i = 0; i < attributes.length; i++) {
        const currentAttribute = attributes[i]

        for (const [currentKey, currentValue] of Object.entries(this[currentAttribute])) {
          let translation = {
            locale: currentKey,
            empty: false
          }

          translation[currentAttribute] = currentValue

          const currentObjState = this.entityData.newTranslations[currentKey]

          this.entityData.newTranslations[currentKey] = {...currentObjState, ...translation}
        }
      }
    },
    updateCurrentLocale (locale) {
      this.currentLocale = locale
      this.successMessage = null
    },
    updateEntityData (updatedData) {
      this.entityData = updatedData
    },
    updateLoading (loading) {
      this.loading = loading
    },
    updateSnackbar (snackbar) {
      this.snackbar = snackbar
    },
    updateSuccessMessage (successMessage) {
      this.successMessage = successMessage
    },
    updateValid (valid) {
      this.valid = valid
    }
  }
}
</script>
