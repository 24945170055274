<template>
  <v-card>
    <Loader
      v-if="loading"
      :key="generateUniqueId()"
      :loader-state="loading"
    ></Loader>

    <v-card-title class="float-left">
      Détails d'un hébergement localisé : {{ renting ? renting.translations.fr.name : '-' }}
    </v-card-title>

    <LocalSwitcher
      @updateCurrentLocale="updateCurrentLocale($event)"
    ></LocalSwitcher>

    <v-card-text>
      <v-form
        v-model="valid"
        class="multi-col-validation mt-5"
        @submit.prevent="checkForm"
      >
        <p v-if="errors.length">
          <v-alert
            v-for="(error, index) in errors"
            :key="index"
            text
            dense
            type="error"
          >
            {{ error.title }}
          </v-alert>
        </p>

        <Snackbar
          v-if="successMessage"
          :key="generateUniqueId()"
          :snackbar-state="snackbar"
          :success-message="successMessage"
        ></Snackbar>

        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-tabs
              grow
            >
              <v-tab>
                <v-icon left>
                  {{ icons.mdiPencil }}
                </v-icon>
                CMS
              </v-tab>
              <v-tab>
                <v-icon left>
                  {{ icons.mdiCameraOutline }}
                </v-icon>
                Images
              </v-tab>

              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col
                        cols="6"
                        md="6"
                      >
                        <h4>Description de l'hébergement localisé</h4>
                        <br>
                        <v-text-field
                          v-model="name[currentLocale]"
                          label="Nom"
                          class="mb-5"
                          outlined
                          dense
                          placeholder="Nom"
                          hide-details
                        >
                          <template v-slot:append>
                            <country-flag
                              class="inner-input-flag"
                              :country="currentLocale === 'en' ? 'gb' : currentLocale"
                              size="normal"
                              shadow
                            />
                          </template>
                        </v-text-field>

                        <v-text-field
                          v-model="slug[currentLocale]"
                          label="Slug"
                          class="mb-5"
                          outlined
                          dense
                          placeholder="Slug"
                          hide-details
                        >
                          <template v-slot:append>
                            <country-flag
                              class="inner-input-flag"
                              :country="currentLocale === 'en' ? 'gb' : currentLocale"
                              size="normal"
                              shadow
                            />
                          </template>
                        </v-text-field>

                        <v-text-field
                          v-model="title[currentLocale]"
                          label="Titre"
                          class="mb-5"
                          outlined
                          dense
                          placeholder="Titre"
                          hide-details
                        >
                          <template v-slot:append>
                            <country-flag
                              class="inner-input-flag"
                              :country="currentLocale === 'en' ? 'gb' : currentLocale"
                              size="normal"
                              shadow
                            />
                          </template>
                        </v-text-field>

                        <v-text-field
                          v-model="subTitle[currentLocale]"
                          label="Sous-titre"
                          class="mb-5"
                          outlined
                          dense
                          placeholder="Titre"
                          hide-details
                        >
                          <template v-slot:append>
                            <country-flag
                              class="inner-input-flag"
                              :country="currentLocale === 'en' ? 'gb' : currentLocale"
                              size="normal"
                              shadow
                            />
                          </template>
                        </v-text-field>

                        <v-switch
                          v-model="entityData.enabled"
                          :label="'Etat : ' + (entityData.enabled === true ? 'Activé' : 'Désactivé')"
                        ></v-switch>

                        <h4>SEO</h4>
                        <br>

                        <v-text-field
                          v-model="seoTitle[currentLocale]"
                          label="Titre SEO"
                          class="mb-5"
                          outlined
                          dense
                          placeholder="Titre SEO"
                          hide-details
                        >
                          <template v-slot:append>
                            <country-flag
                              class="inner-input-flag"
                              :country="currentLocale === 'en' ? 'gb' : currentLocale"
                              size="normal"
                              shadow
                            />
                          </template>
                        </v-text-field>

                        <v-text-field
                          v-model="seoDescription[currentLocale]"
                          label="Description SEO"
                          class="mb-5"
                          outlined
                          dense
                          placeholder="Description SEO"
                          hide-details
                        >
                          <template v-slot:append>
                            <country-flag
                              class="inner-input-flag"
                              :country="currentLocale === 'en' ? 'gb' : currentLocale"
                              size="normal"
                              shadow
                            />
                          </template>
                        </v-text-field>

                        <v-text-field
                          v-model="seoKeywords[currentLocale]"
                          label="Mot clés"
                          class="mb-5"
                          outlined
                          dense
                          placeholder="Mot clés"
                          hide-details
                        >
                          <template v-slot:append>
                            <country-flag
                              class="inner-input-flag"
                              :country="currentLocale === 'en' ? 'gb' : currentLocale"
                              size="normal"
                              shadow
                            />
                          </template>
                        </v-text-field>

                        <h4>Sélection de la page hébergement</h4>
                        <br>
                        <v-select
                          v-model="rentingSelection"
                          :items="rentings"
                          outlined
                          chips
                          persistent-hint
                        ></v-select>

                        <br>

                        <h4>Sélection de la region web</h4>
                        <br>
                        <v-select
                          v-model="regionWebSelection"
                          :items="regionsWeb"
                          outlined
                          chips
                          persistent-hint
                        ></v-select>
                      </v-col>
                      <v-col
                        cols="6"
                        md="6"
                      >
                        <div class="content-ckeditor-region">
                          <h4>Description</h4>
                          <br>
                          <ckeditor
                            v-model="description[currentLocale]"
                            :editor="editor"
                            :config="editorConfig"
                            height="10"
                            row="50"
                          />
                          <country-flag
                            class="inner-input-flag content-flag-placeholder"
                            :country="currentLocale === 'en' ? 'gb' : currentLocale"
                            size="normal"
                            shadow
                          />
                        </div>

                        <br>

                        <div class="content-ckeditor-region">
                          <h4>Description bas de page</h4>
                          <br>
                          <ckeditor
                            v-model="bottomDescription[currentLocale]"
                            :editor="editor"
                            :config="editorConfig"
                            height="10"
                            row="50"
                          />
                          <country-flag
                            class="inner-input-flag content-flag-placeholder"
                            :country="currentLocale === 'en' ? 'gb' : currentLocale"
                            size="normal"
                            shadow
                          />
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <ImageHandler
                      :key="generateUniqueId()"
                      :entity-data="entityData"
                      image-label="Photo principale"
                      image-name="mainImage"
                      :icons="icons"
                      :valid="valid"
                      :loading="loading"
                      :success-message="successMessage"
                      :snackbar="snackbar"
                      @update="updateEntityData($event)"
                    ></ImageHandler>

                    <br>

                    <v-divider class="v-divider-entity mt-5 mb-5"></v-divider>

                    <ImageHandler
                      :key="generateUniqueId()"
                      :entity-data="entityData"
                      image-label="Photo mobile"
                      image-name="mobileImage"
                      :icons="icons"
                      :valid="valid"
                      :loading="loading"
                      :success-message="successMessage"
                      :snackbar="snackbar"
                      @update="updateEntityData($event)"
                    ></ImageHandler>

                    <v-divider class="v-divider-entity mt-5 mb-5"></v-divider>

                    <h4>Texte bannière</h4>
                    <br>

                    <v-text-field
                      v-model="txtZone1[currentLocale]"
                      label="Texte zone 1"
                      class="mb-5"
                      outlined
                      dense
                      placeholder="Texte zone 1"
                      hide-details
                    >
                      <template v-slot:append>
                        <country-flag
                          class="inner-input-flag"
                          :country="currentLocale === 'en' ? 'gb' : currentLocale"
                          size="normal"
                          shadow
                        />
                      </template>
                    </v-text-field>

                    <v-text-field
                      v-model="txtZone2[currentLocale]"
                      label="Texte zone 2"
                      class="mb-5"
                      outlined
                      dense
                      placeholder="Texte zone 2"
                      hide-details
                    >
                      <template v-slot:append>
                        <country-flag
                          class="inner-input-flag"
                          :country="currentLocale === 'en' ? 'gb' : currentLocale"
                          size="normal"
                          shadow
                        />
                      </template>
                    </v-text-field>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-btn
              class="mt-5 mr-2"
              color="primary"
              :disabled="!valid"
              type="submit"
            >
              Valider
            </v-btn>

            <v-btn
              class="mt-5"
              text
              :to="{ name: 'locationRentingList' }"
            >
              Retour
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Vue from 'vue'
import { mdiEyeOutline, mdiEyeOffOutline, mdiCameraOutline, mdiMinusBox, mdiPencil, mdiCompassRose, mdiThumbUp, mdiSkiWater } from '@mdi/js'
import config from '../../../config'
import LocalSwitcher from '@/components/Common/LocalSwitcher'
import Snackbar from '@/components/Common/Snackbar'
import Loader from '@/components/Common/Loader'
import ImageHandler from '@/components/Common/ImageHandler'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import FullEditor from 'ckeditor5-custom-build/build/ckeditor'

export default {
  components: {
    ImageHandler,
    Snackbar,
    LocalSwitcher,
    Loader,
    ckeditor: CKEditor.component
  },
  data () {
    return {
      valid: false,
      errors: [],
      snackbar: false,
      successMessage: null,
      loading: false,
      currentLocale: 'fr',
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiCameraOutline,
        mdiMinusBox,
        mdiPencil,
        mdiCompassRose,
        mdiSkiWater,
        mdiThumbUp
      },
      editor: FullEditor,
      editorConfig: {
        toolbar: {
          shouldNotGroupWhenFull: true
        },
        heading: {
          options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
            { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
            { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
            { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
            { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' }
          ]
        },
        htmlSupport: {
          allow: [
            {
              name: /.*/,
              attributes: true,
              classes: true,
              styles: true
            }
          ]
        },
        simpleUpload: {
          uploadUrl: config.apiUrl + '/api/medias',
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        }
      },
      regionsWeb: [],
      regionWebSelection: [],
      rentings: [],
      rentingSelection: [],
      id: [],
      name: [],
      slug: [],
      title: [],
      subTitle: [],
      description: [],
      bottomDescription: [],
      seoTitle: [],
      seoDescription: [],
      seoKeywords: [],
      txtZone1: [],
      txtZone2: [],
      renting: null,
      entityData: {
        id: null,
        enabled: null,
        currentLocale: 'fr',
        defaultLocale: 'fr',
        newTranslations: {},
        mainImage: {
          id: null,
          title: null
        },
        mobileImage: {
          id: null,
          title: null
        }
      }
    }
  },
  created () {
    this.getRentings()
  },
  methods: {
    checkForm () {
      this.errors = []

      if (this.valid) {
        this.putRenting()
      }
    },
    apiUrl () {
      return config.apiUrl
    },
    getRentings () {
      this.loading = true

      const params = {
        limit: 150
      }

      Vue.prototype.$http
        .get(config.apiUrl + '/api/accommodation/rentings', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: params
        })
        .then(response => {
          if (response.status === 200) {
            const rentings = response.data

            for (let i = 0; i < rentings.length; i++) {
              const currentRenting = rentings[i]

              this.rentings.push({
                text: currentRenting.name,
                value: currentRenting.id
              })
            }
          }

          this.getRegionsWeb()
        })
        .catch(() => {
          this.loading = false
        })
    },
    getRegionsWeb () {
      this.loading = true

      const params = {
        limit: 150
      }

      Vue.prototype.$http
        .get(config.apiUrl + '/api/location/regions-web', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: params
        })
        .then(response => {
          if (response.status === 200) {
            const regionsWeb = response.data

            for (let i = 0; i < regionsWeb.length; i++) {
              const currentRegionWeb = regionsWeb[i]

              this.regionsWeb.push({
                text: currentRegionWeb.translations.fr.name,
                value: currentRegionWeb.id
              })
            }
          }

          this.getRenting()

        })
        .catch(() => {
          this.loading = false
        })
    },
    getRenting () {
      const rentingId = this.$route.params.locationRentingId

      this.loading = true

      Vue.prototype.$http
        .get(config.apiUrl + '/api/location/rentings/' + rentingId, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 200) {
            this.renting = response.data
            this.entityData.id = this.renting.id
            this.entityData.enabled = this.renting.enabled

            if (this.renting.mainImage) {
              this.entityData.mainImage = this.renting.mainImage
            }

            if (this.renting.mobileImage) {
              this.entityData.mobileImage = this.renting.mobileImage
            }

            this.entityData.renting = this.renting.renting

            if (this.entityData.renting) {
              this.rentingSelection = this.entityData.renting.id
            }

            this.entityData.regionWeb = this.renting.regionWeb

            if (this.entityData.regionWeb) {
              this.regionWebSelection = this.entityData.regionWeb.id
            }

            const translations = this.renting.translations

            for (const [currentKey] of Object.entries(translations)) {
              let currentTranslation = translations[currentKey]

              this.id[currentKey] = currentTranslation.id
              this.name[currentKey] = currentTranslation.name
              this.slug[currentKey] = currentTranslation.slug
              this.title[currentKey] = currentTranslation.title
              this.subTitle[currentKey] = currentTranslation.subTitle
              this.description[currentKey] = currentTranslation.description
              this.bottomDescription[currentKey] = currentTranslation.bottomDescription
              this.seoTitle[currentKey] = currentTranslation.seoTitle
              this.seoDescription[currentKey] = currentTranslation.seoDescription
              this.seoKeywords[currentKey] = currentTranslation.seoKeywords
              this.txtZone1[currentKey] = currentTranslation.txtZone1
              this.txtZone2[currentKey] = currentTranslation.txtZone2
            }

            this.valid = true
          }

          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    putRenting () {
      this.loading = true
      this.valid = false
      this.successMessage = null

      this.handleTranslations([
        'id',
        'name',
        'slug',
        'title',
        'subTitle',
        'description',
        'bottomDescription',
        'seoTitle',
        'seoDescription',
        'seoKeywords',
        'txtZone1',
        'txtZone2'
      ])

      if (this.rentingSelection.length) {
        this.entityData.renting = {
          id: this.rentingSelection
        }
      }

      if (this.regionWebSelection.length) {
        this.entityData.regionWeb = {
          id: this.regionWebSelection
        }
      }

      Vue.prototype.$http
        .put(config.apiUrl + '/api/location/rentings/' + this.entityData.id, this.entityData, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 200) {
            this.renting = response.data

            const translations = this.renting.translations

            for (const [currentKey] of Object.entries(translations)) {
              let currentTranslation = translations[currentKey]

              this.id[currentKey] = currentTranslation.id
              this.name[currentKey] = currentTranslation.name
              this.slug[currentKey] = currentTranslation.slug
              this.title[currentKey] = currentTranslation.title
              this.subTitle[currentKey] = currentTranslation.subTitle
              this.description[currentKey] = currentTranslation.description
              this.bottomDescription[currentKey] = currentTranslation.bottomDescription
              this.seoTitle[currentKey] = currentTranslation.seoTitle
              this.seoDescription[currentKey] = currentTranslation.seoDescription
              this.seoKeywords[currentKey] = currentTranslation.seoKeywords
              this.txtZone1[currentKey] = currentTranslation.txtZone1
              this.txtZone2[currentKey] = currentTranslation.txtZone2
            }

            this.snackbar = true
            this.successMessage = 'Hébergement localisé mis à jour avec succès.'
            this.valid = true
          }

          this.loading = false
        })
        .catch(e => {
          const response = e.response

          this.successMessage = null

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
          }

          this.valid = true
          this.loading = false
        })
    },
    handleTranslations (attributes) {
      for (let i = 0; i < attributes.length; i++) {
        const currentAttribute = attributes[i]

        for (const [currentKey, currentValue] of Object.entries(this[currentAttribute])) {
          let translation = {
            locale: currentKey,
            empty: false
          }

          translation[currentAttribute] = currentValue

          const currentObjState = this.entityData.newTranslations[currentKey]

          this.entityData.newTranslations[currentKey] = {...currentObjState, ...translation}
        }
      }
    },
    updateCurrentLocale (locale) {
      this.currentLocale = locale
      this.successMessage = null
    },
    updateEntityData (updatedData) {
      this.entityData = updatedData
    },
    updateLoading (loading) {
      this.loading = loading
    },
    updateSnackbar (snackbar) {
      this.snackbar = snackbar
    },
    updateSuccessMessage (successMessage) {
      this.successMessage = successMessage
    },
    updateValid (valid) {
      this.valid = valid
    }
  }
}
</script>

