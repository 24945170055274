import Vue from 'vue'
import VueRouter from 'vue-router'
import translationRoutes from '@/views/Translation/routes'
import contentPageRoutes from '@/views/Content/Page/routes'
import contentBlockRoutes from '@/views/Content/Block/routes'
import seoMetadataRoutes from '@/views/Seo/Metadata/routes'
import seoMetadataParameterRoutes from '@/views/Seo/MetadataParameter/routes'
import seoBlockedUrlRoutes from '@/views/Seo/BlockedUrl/routes'
import seoQueryParamsWhitelistRoutes from '@/views/Seo/QueryParamsWhitelist/routes'
import userRoutes from '@/views/User/routes'
import engineRoutes from '@/views/Engine/routes'
import campingRoutes from '@/views/Camping/routes'
import securityRoutes from '@/views/Security/routes'
import selectionRoutes from '@/views/Home/Selection/routes'
import sliderRoutes from '@/views/Home/Slide/routes'
import socialNetworkPostRoutes from '@/views/Home/SocialNetworkPost/routes'
import linkCloudRoutes from '@/views/Home/LinkCloud/routes'
import regionCatalogueRoutes from '@/views/Location/RegionCatalogue/routes'
import regionWebRoutes from '@/views/Location/RegionWeb/routes'
import regionSeoRoutes from '@/views/Location/RegionSeo/routes'
import locationRentingRoutes from '@/views/Location/Renting/routes'
import thematicRoutes from '@/views/Thematic/routes'
import rentingRoutes from '@/views/Renting/routes'
import specialOfferRoutes from '@/views/SpecialOffer/routes'
import serviceCategoryRoutes from '@/views/Service/Category/routes'
import worksCouncilRoutes from '@/views/WorksCouncil/routes'
import contactWorksCouncilRoutes from '@/views/Contact/WorksCouncil/routes'
import contactGroupFormSheetRoutes from '@/views/Contact/GroupFormSheet/routes'

Vue.use(VueRouter)

let routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Front/Home.vue'),
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '*',
    redirect: 'error-404'
  }
]

routes = routes
  .concat(translationRoutes)
  .concat(contentPageRoutes)
  .concat(contentBlockRoutes)
  .concat(seoMetadataRoutes)
  .concat(seoMetadataParameterRoutes)
  .concat(seoBlockedUrlRoutes)
  .concat(seoQueryParamsWhitelistRoutes)
  .concat(userRoutes)
  .concat(engineRoutes)
  .concat(campingRoutes)
  .concat(securityRoutes)
  .concat(selectionRoutes)
  .concat(linkCloudRoutes)
  .concat(sliderRoutes)
  .concat(socialNetworkPostRoutes)
  .concat(regionCatalogueRoutes)
  .concat(regionWebRoutes)
  .concat(regionSeoRoutes)
  .concat(locationRentingRoutes)
  .concat(thematicRoutes)
  .concat(rentingRoutes)
  .concat(specialOfferRoutes)
  .concat(serviceCategoryRoutes)
  .concat(worksCouncilRoutes)
  .concat(contactWorksCouncilRoutes)
  .concat(contactGroupFormSheetRoutes)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
