<template>
  <v-card>
    <Loader
      v-if="loading"
      :key="generateUniqueId()"
      :loader-state="loading"
    ></Loader>

    <v-card-title>Whitelist des paramètres</v-card-title>

    <v-card-text>
      <v-form
        v-model="valid"
        class="multi-col-validation mt-5"
        @submit.prevent="checkForm"
      >
        <p v-if="errors && errors.length">
          <v-alert
            v-for="(error, index) in errors"
            :key="index"
            text
            dense
            type="error"
          >
            {{ error.title }}
          </v-alert>
        </p>

        <Snackbar
          v-if="successMessage"
          :key="generateUniqueId()"
          :snackbar-state="snackbar"
          :success-message="successMessage"
        ></Snackbar>

        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-textarea
              v-model="entityData.whitelist"
              :height="500"
              label="Whitelist"
              outlined
              hint="Whitelist des paramètres, une ligne par paramètre"
              persistent-hint
              :rules="[v => !!v || 'La whitelist est requise']"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-btn
              class="mt-5 mr-2"
              color="primary"
              :disabled="!valid"
              type="submit"
            >
              Valider
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Vue from 'vue'
import config from '../../../config'
import Snackbar from '@/components/Common/Snackbar'
import Loader from '@/components/Common/Loader'
import {
  mdiCameraOutline,
  mdiCompassRose,
  mdiEyeOffOutline,
  mdiEyeOutline,
  mdiMinusBox,
  mdiPencil, mdiSkiWater, mdiThumbUp
} from '@mdi/js'

export default {
  components: {
    Snackbar,
    Loader
  },
  data () {
    return {
      valid: false,
      errors: [],
      snackbar: false,
      successMessage: null,
      loading: false,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiCameraOutline,
        mdiMinusBox,
        mdiPencil,
        mdiCompassRose,
        mdiSkiWater,
        mdiThumbUp
      },
      id: [],
      queryParamsWhitelist: null,
      entityData: {
        id: null,
        whitelist: []
      }
    }
  },
  computed: {
  },
  created () {
    this.getQueryParamsWhitelist()
  },
  methods: {
    checkForm () {
      this.errors = []

      if (this.valid) {
        this.queryParamsWhitelist.id ? this.putQueryParamsWhitelist() : this.postQueryParamsWhitelist()
      }
    },
    getQueryParamsWhitelist () {
      this.loading = true

      Vue.prototype.$http
        .get(config.apiUrl + '/api/query-params-whitelist', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 200) {
            this.queryParamsWhitelist = response.data
            this.entityData.id = this.queryParamsWhitelist.id
            this.entityData.whitelist = this.queryParamsWhitelist.whitelist
          }

          this.valid = true
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    postQueryParamsWhitelist () {
      this.valid = false
      this.successMessage = null
      this.loading = true

      if (!Array.isArray(this.entityData.whitelist)) {
        this.entityData.whitelist = [this.entityData.whitelist]
      }

      Vue.prototype.$http
        .post(config.apiUrl + '/api/query-params-whitelist', this.entityData, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 201) {
            this.selection = response.data
            this.errors = []
            this.snackbar = true
            this.successMessage = 'Whitelist mise à jour avec succès.'
            this.valid = true
            this.queryParamsWhitelist.id = response.data.id
          }

          this.loading = false
        })
        .catch(e => {
          const response = e.response

          this.successMessage = null

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
          }

          this.valid = true
          this.loading = false
        })
    },
    putQueryParamsWhitelist () {
      this.valid = false
      this.successMessage = null
      this.loading = true

      if (!Array.isArray(this.entityData.whitelist)) {
        this.entityData.whitelist = [this.entityData.whitelist]
      }

      Vue.prototype.$http
        .put(config.apiUrl + '/api/query-params-whitelist/' + this.queryParamsWhitelist.id, this.entityData, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 200) {
            this.errors = []
            this.snackbar = true
            this.successMessage = 'Whitelist mise à jour avec succès.'
            this.valid = true

            this.queryParamsWhitelist = response.data
          }

          this.loading = false
        })
        .catch(e => {
          const response = e.response

          this.successMessage = null

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
          }

          this.valid = true
          this.loading = false
        })
    },
    updateEntityData (updatedData) {
      this.entityData = updatedData
    },
    updateLoading (loading) {
      this.loading = loading
    },
    updateSnackbar (snackbar) {
      this.snackbar = snackbar
    },
    updateSuccessMessage (successMessage) {
      this.successMessage = successMessage
    },
    updateValid (valid) {
      this.valid = valid
    }
  }
}
</script>
