<template>
  <v-col cols="12">
    <Loader
      v-if="loading"
      :key="generateUniqueId()"
      :loader-state="loading"
    ></Loader>

    <v-card>
      <v-card-title>Offres personnalisées</v-card-title>

      <v-card-text>
        <div class="global-actions">
          <router-link :to="{ name: 'specialOfferPersonalizedCreate' }">
            <v-btn
              small
              color="primary"
              class="mt-5"
            >
              Ajouter une offre personnalisée
            </v-btn>
          </router-link>
        </div>
      </v-card-text>

      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-uppercase">
                Nom
              </th>
              <th class="text-center text-uppercase">
                Etat
              </th>
              <th class="text-center text-uppercase">
                Actions
              </th>
            </tr>
          </thead>
          <tbody v-if="blocks.length">
            <tr
              v-for="(block, blockIndex) in blocks"
              :key="'b' + blockIndex"
            >
              <td class="font-weight-bold">
                {{ block.title }}
              </td>
              <td class="text-center">
                <v-chip
                  v-if="block.enabled"
                  color="success"
                  text-color="white"
                  small
                >
                  Activée
                </v-chip>
                <v-chip
                  v-if="!block.enabled"
                  color="error"
                  text-color="white"
                  small
                >
                  Désactivée
                </v-chip>
              </td>
              <td class="text-center">
                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      color="warning"
                      fab
                      dark
                      x-small
                      v-bind="attrs"
                      :to="{ name: 'specialOfferPersonalizedDetails', params: { specialOfferId: block.id }}"
                    >
                      <v-icon dark>
                        {{ icons.mdiEye }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Consulter</span>
                </v-tooltip>

                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      class="ml-2"
                      color="error"
                      fab
                      dark
                      x-small
                      v-bind="attrs"
                      @click="openDeleteBlockDialog(block)"
                    >
                      <v-icon dark>
                        {{ icons.mdiTrashCan }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Supprimer</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
          <tbody v-if="!blocks.length">
            <tr>
              <td>
                <span v-if="loading === false">Aucun éléments.</span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <DeleteBlockDialog
        :key="Date.now()"
        :delete-block-dialog-state="deleteBlockDialogState"
        :block="blockToDelete"
        @closeDeleteBlockDialog="closeDeleteBlockDialog($event)"
      ></DeleteBlockDialog>
    </v-card>
  </v-col>
</template>

<script>
import { mdiPlay, mdiPen, mdiEye, mdiTrashCan, mdiCheck, mdiCloseCircleOutline, mdiBackupRestore, mdiDatabaseSearchOutline } from '@mdi/js'
import Vue from 'vue'
import DeleteBlockDialog from '@/components/Dialog/ContentDialog/Block/DeleteBlockDialog'
import config from '../../../config'
import Loader from '@/components/Common/Loader'

export default {
  components: {
    DeleteBlockDialog,
    Loader
  },
  data: () => ({
    nextPage: 1,
    tag: '',
    tags: [],
    blocks: [],
    blockToDelete: null,
    deleteBlockDialogState: false,
    loading: false,
    icons: {
      mdiPlay,
      mdiPen,
      mdiEye,
      mdiTrashCan,
      mdiCheck,
      mdiCloseCircleOutline,
      mdiBackupRestore,
      mdiDatabaseSearchOutline
    }
  }),
  computed: {
    isHandlingBlockScroll () {
      return this.$store.getters.isHandlingBlockScroll
    }
  },
  created () {
    this.getBlocks()

    window.removeEventListener('scroll', this.handleScroll)

    setTimeout(() => {
      window.addEventListener('scroll', this.handleScroll)
    }, 1000)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll () {
      const wrapperOffsetHeight = document.querySelector('.v-application--wrap').offsetHeight
      const bottomOfWindow = (document.documentElement.scrollTop + window.innerHeight) === wrapperOffsetHeight

      if (bottomOfWindow && !this.isHandlingBlockScroll) {
        this.getBlocks()
      }
    },
    getBlocks () {
      this.loading = true

      const params = {
        page: this.nextPage,
        personalizedOffer: true
      }

      const terms = []

      for (let i = 0; i < this.tags.length; i++) {
        terms.push(this.tags[i].text)
      }

      if (terms.length) {
        params.terms = terms.join(',')
      }

      this.$store.commit('setHandlingBlockScroll', true)

      Vue.prototype.$http
        .get(config.apiUrl + '/api/content/blocks', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: params
        })
        .then(response => {
          if (response.status === 200) {
            const blocks = response.data

            if (blocks && blocks.length) {
              this.blocks.push(...blocks)

              this.nextPage = this.nextPage + 1
            }
          }

          this.loading = false
          this.$store.commit('setHandlingBlockScroll', false)
        })
        .catch(e => {
          const response = e.response

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
          }

          this.valid = true
          this.loading = false
          this.$store.commit('setHandlingBlockScroll', false)
        })
    },
    openDeleteBlockDialog (block) {
      this.deleteBlockDialogState = true
      this.blockToDelete = block
    },
    closeDeleteBlockDialog (deleteState) {
      if (deleteState === true) {
        this.blocks = this.blocks.filter(element => element.id !== this.blockToDelete.id)
      }

      this.deleteBlockDialogState = false
    }
  }
}
</script>
