export default [
  {
    path: '/seo/whitelist-parametres',
    name: 'QueryParamsWhitelistDetails',
    component: () => import('@/views/Seo/QueryParamsWhitelist/Details.vue'),
    meta: {
      layout: 'content'
    }
  }
]
