<template>
  <v-col cols="12">
    <Loader
      v-if="loading"
      :key="generateUniqueId()"
      :loader-state="loading"
    ></Loader>

    <v-card>
      <v-card-title>Url bloquées</v-card-title>

      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-uppercase">
                Url
              </th>
              <th class="text-center text-uppercase">
                Paramètres
              </th>
              <th class="text-center text-uppercase">
                Date
              </th>
            </tr>
          </thead>
          <tbody v-if="blockedUrls.length">
            <tr
              v-for="(blockedUrl, blockedUrlIndex) in blockedUrls"
              :key="'u' + blockedUrlIndex"
            >
              <td class="font-weight-bold">
                {{ blockedUrl.url }}
              </td>
              <td class="text-center">
                {{ blockedUrl.forbiddenQueryParams }}
              </td>
              <td class="text-center">
                {{ blockedUrl.createdAt }}
              </td>
            </tr>
          </tbody>
          <tbody v-if="!blockedUrls.length">
            <tr>
              <td>
                <span v-if="loading === false">Aucun éléments.</span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </v-col>
</template>

<script>
import { mdiPlay, mdiPen, mdiEye, mdiTrashCan } from '@mdi/js'
import Vue from 'vue'
import config from '../../../config'
import Loader from '@/components/Common/Loader'

export default {
  components: {
    Loader
  },
  data: () => ({
    blockedUrls: [],
    loading: false,
    icons: {
      mdiPlay,
      mdiPen,
      mdiEye,
      mdiTrashCan
    }
  }),
  created () {
    this.getBlockedUrls()
  },
  methods: {
    getBlockedUrls () {
      this.loading = true

      Vue.prototype.$http
        .get(config.apiUrl + '/api/seo/blocked-urls', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 200) {
            const data = response.data

            if (data) {
              this.blockedUrls = data
            }
          }

          this.loading = false
        })
        .catch(e => {
          const response = e.response

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
          }

          this.valid = true
          this.loading = false
        })
    }
  }
}
</script>
