<template>
  <v-card>
    <Loader
      v-if="loading"
      :key="generateUniqueId()"
      :loader-state="loading"
    ></Loader>

    <v-card-title class="float-left">
      Création d'une page
    </v-card-title>

    <LocalSwitcher
      @updateCurrentLocale="updateCurrentLocale($event)"
    ></LocalSwitcher>

    <v-card-text>
      <v-form
        v-model="valid"
        class="multi-col-validation mt-5"
        @submit.prevent="checkForm"
      >
        <p v-if="errors && errors.length">
          <v-alert
            v-for="(error, index) in errors"
            :key="index"
            text
            dense
            type="error"
          >
            {{ error.title ? error.title : error.message }}
          </v-alert>
        </p>

        <Snackbar
          v-if="successMessage"
          :key="generateUniqueId()"
          :snackbar-state="snackbar"
          :success-message="successMessage"
        ></Snackbar>

        <v-row>
          <v-col
            cols="8"
            md="8"
          >
            <v-text-field
              v-model="title[currentLocale]"
              label="Titre"
              class="mb-5"
              outlined
              dense
              placeholder="Titre"
              hide-details
            >
              <template v-slot:append>
                <country-flag
                  class="inner-input-flag"
                  :country="currentLocale === 'en' ? 'gb' : currentLocale"
                  size="normal"
                  shadow
                />
              </template>
            </v-text-field>

            <v-text-field
              v-model="slug[currentLocale]"
              label="Slug"
              class="mb-5"
              outlined
              dense
              placeholder="Titre"
              hide-details
            >
              <template v-slot:append>
                <country-flag
                  class="inner-input-flag"
                  :country="currentLocale === 'en' ? 'gb' : currentLocale"
                  size="normal"
                  shadow
                />
              </template>
            </v-text-field>

            <v-switch
              v-model="entityData.enabled"
              :label="'Etat : ' + (entityData.enabled === true ? 'activé' : 'désactivé')"
            ></v-switch>

            <v-switch
              v-model="entityData.inMenu"
              :label="'Dans le menu : ' + (entityData.inMenu === true ? 'oui' : 'Non')"
            ></v-switch>

            <div class="content-ckeditor">
              <ckeditor
                v-model="description[currentLocale]"
                :editor="editor"
                :config="editorConfig"
                height="10"
                row="50"
              />
              <country-flag
                class="inner-input-flag content-flag"
                :country="currentLocale === 'en' ? 'gb' : currentLocale"
                size="normal"
                shadow
              />
            </div>
          </v-col>
          <v-col
            cols="4"
            md="4"
          >
            <h4 class="mb-5">
              Gestion des blocks
            </h4>

            <v-divider></v-divider>

            <v-virtual-scroll
              :items="blocks"
              :item-height="40"
              height="200"
            >
              <template v-slot:default="{ item }">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>

                  <v-btn
                    depressed
                    small
                    @click="add(item)"
                  >
                    <v-btn
                      color="success"
                      fab
                      dark
                      x-small
                    >
                      <v-icon dark>
                        {{ icons.mdiPlusBox }}
                      </v-icon>
                    </v-btn>
                  </v-btn>
                </v-list-item>
              </template>
            </v-virtual-scroll>

            <v-divider></v-divider>

            <h4 class="mt-5 mb-5">
              Sélection
            </h4>

            <div v-if="list.length === 0">
              <span class="font-italic">
                Aucun blocks sélectionnés pour cette page.
              </span>
            </div>

            <Draggable
              :list="list"
              :disabled="!enabled"
              class="list-group"
              ghost-class="ghost"
              :move="checkMove"
              @start="dragging = true"
              @end="dragging = false"
            >
              <div
                v-for="element in list"
                :key="element.name"
                class="list-group-item"
              >
                <div class="float-left">
                  <span class="list-item-label">{{ element.title }}</span>
                </div>

                <div
                  class="float-right"
                  @click="remove(element)"
                >
                  <v-btn
                    color="error"
                    fab
                    dark
                    x-small
                  >
                    <v-icon dark>
                      {{ icons.mdiMinusBox }}
                    </v-icon>
                  </v-btn>
                </div>

                <div class="clear"></div>
              </div>
            </Draggable>

            <v-divider class="v-divider-entity mt-6 mb-5"></v-divider>

            <h4 class="mb-5">
              Informations SEO
            </h4>

            <v-text-field
              v-model="seoTitle[currentLocale]"
              label="Titre"
              class="mb-5"
              outlined
              dense
              placeholder="Titre"
              hide-details
            >
              <template v-slot:append>
                <country-flag
                  class="inner-input-flag"
                  :country="currentLocale === 'en' ? 'gb' : currentLocale"
                  size="normal"
                  shadow
                />
              </template>
            </v-text-field>

            <v-text-field
              v-model="seoDescription[currentLocale]"
              label="Description"
              class="mb-5"
              outlined
              dense
              placeholder="Description"
              hide-details
            >
              <template v-slot:append>
                <country-flag
                  class="inner-input-flag"
                  :country="currentLocale === 'en' ? 'gb' : currentLocale"
                  size="normal"
                  shadow
                />
              </template>
            </v-text-field>

            <v-text-field
              v-model="seoKeywords[currentLocale]"
              label="Mot clés"
              class="mb-5"
              outlined
              dense
              placeholder="Mot clés"
              hide-details
            >
              <template v-slot:append>
                <country-flag
                  class="inner-input-flag"
                  :country="currentLocale === 'en' ? 'gb' : currentLocale"
                  size="normal"
                  shadow
                />
              </template>
            </v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-btn
              class="mt-5 mr-2"
              color="primary"
              :disabled="!valid"
              type="submit"
            >
              Valider
            </v-btn>

            <v-btn
              class="mt-5"
              text
              :to="{ name: 'pageList' }"
            >
              Retour
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Vue from 'vue'
import config from '../../../config'
import router from '@/router'
import LocalSwitcher from '@/components/Common/LocalSwitcher'
import Snackbar from '@/components/Common/Snackbar'
import Loader from '@/components/Common/Loader'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import FullEditor from 'ckeditor5-custom-build/build/ckeditor'
import Draggable from 'vuedraggable'
import {
  mdiCheck,
  mdiCloseCircleOutline,
  mdiEye, mdiMinusBox,
  mdiPen,
  mdiPlay,
  mdiPlusBox,
  mdiTrashCan
} from '@mdi/js'

export default {
  components: {
    LocalSwitcher,
    Snackbar,
    Loader,
    ckeditor: CKEditor.component,
    Draggable
  },
  data () {
    return {
      valid: false,
      currentLocale: 'fr',
      errors: [],
      title: [],
      description: [],
      slug: [],
      seoTitle: [],
      seoDescription: [],
      seoKeywords: [],
      publicationStartDate: null,
      publicationEndDate: null,
      enabled: true,
      inMenu: false,
      snackbar: false,
      successMessage: null,
      loading: false,
      editor: FullEditor,
      editorConfig: {
        toolbar: {
          shouldNotGroupWhenFull: true
        },
        heading: {
          options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
            { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
            { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
            { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
            { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' }
          ]
        },
        htmlSupport: {
          allow: [
            {
              name: /.*/,
              attributes: true,
              classes: true,
              styles: true
            }
          ]
        },
        simpleUpload: {
          uploadUrl: config.apiUrl + '/api/medias',
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        }
      },
      blocks: [],
      list: [],
      dragging: false,
      icons: {
        mdiPlay,
        mdiPen,
        mdiEye,
        mdiTrashCan,
        mdiCheck,
        mdiCloseCircleOutline,
        mdiPlusBox,
        mdiMinusBox
      },
      entityData: {
        enabled: false,
        inMenu: false,
        currentLocale: 'fr',
        defaultLocale: 'fr',
        newTranslations: {}
      }
    }
  },
  created () {
    this.getAvailableBlocks()
  },
  methods: {
    checkForm () {
      this.errors = []

      if (!this.title.fr) {
        this.errors.push({ title: 'Le titre français doit être renseigné.' })

        return
      }

      if (this.valid) {
        this.postPage()
      }
    },
    getAvailableBlocks () {
      this.loading = true

      Vue.prototype.$http
        .get(config.apiUrl + '/api/content/blocks', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 200) {
            const data = response.data

            if (data) {
              this.blocks = data
            }
          }

          this.loading = false
        })
        .catch(e => {
          const response = e.response

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
          }

          this.valid = true
          this.loading = false
        })
    },
    postPage () {
      this.valid = false
      this.loading = true

      this.handleTranslations([
        'title',
        'slug',
        'description',
        'seoTitle',
        'seoDescription',
        'seoKeywords'
      ])

      this.entityData.enabled = this.enabled
      this.entityData.inMenu = this.inMenu

      const pageBlocks = {}

      for (let i = 0; i < this.list.length; i++) {
        const currentBlock = this.list[i]

        pageBlocks[currentBlock.id] = {
          block: {
            id: currentBlock.id,
            namz: currentBlock.translations.fr.title
          },
          position: i
        }
      }

      this.entityData.pageBlocks = pageBlocks

      Vue.prototype.$http
        .post(config.apiUrl + '/api/content/pages', this.entityData, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 201) {
            const page = response.data

            this.errors = []
            this.snackbar = true
            this.successMessage = 'Page créée avec succès.'

            setTimeout(() => {
              router.push({ name: 'pageDetails', params: { pageId: page.id } })
            }, 1500)
          }

          this.loading = false
        })
        .catch(e => {
          const response = e.response

          if (response.status === 400 || response.status === 404) {
            const data = response.data

            if (data.violations) {
              this.errors = data.violations
            } else {
              this.errors = data
            }
          }

          this.valid = true
          this.loading = false
        })
    },
    genRandomIndex (length) {
      return Math.ceil(Math.random() * (length - 1))
    },
    add (item) {
      this.list = this.list.filter(element => element.id !== item.id)

      this.list.push(item)
    },
    remove (item) {
      this.list = this.list.filter(element => element.id !== item.id)
    },
    checkMove: e => {
      window.console.log('Future index: ' + e.draggedContext.futureIndex)
    },
    resolveAttrValue (data, attr) {
      const translations = data.translations

      if (translations.fr) {
        return translations.fr[attr]
      }

      return '-'
    },
    handleTranslations (attributes) {
      for (let i = 0; i < attributes.length; i++) {
        const currentAttribute = attributes[i]

        for (const [currentKey, currentValue] of Object.entries(this[currentAttribute])) {
          let translation = {
            locale: currentKey,
            empty: false
          }

          translation[currentAttribute] = currentValue

          const currentObjState = this.entityData.newTranslations[currentKey]

          this.entityData.newTranslations[currentKey] = {...currentObjState, ...translation}
        }
      }
    },
    updateCurrentLocale (locale) {
      this.currentLocale = locale
      this.successMessage = null
    }
  }
}
</script>

<style scoped>
.buttons {
  margin-top: 35px;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.v-btn:before {
  background-color: transparent;
}
.v-list-item {
  align-items: center;
  display: flex;
  flex: 1 1 100%;
  letter-spacing: normal;
  min-height: 48px;
  outline: none;
  padding: 0 5px;
  position: relative;
  text-decoration: none;
}
.v-list-item__title {
  align-self: center;
  font-size: 0.9rem;
}
.list-group-item {
  position: relative;
  display: block;
  padding: 0.35rem 0.5rem;
  margin-bottom: -1px;
  background-color: #fff;
  border-top: 2px dotted rgba(0,0,0,.125);
  border-bottom: 2px dotted rgba(0,0,0,.125);
}
.list-group-item:hover {
  cursor: pointer;
}
.list-group-item button {
  margin-right: 10px;
}
.list-item-label {
  vertical-align: sub;
}
</style>
